import React, { useState } from 'react';
import Typed from "react-typed";
import AOS from 'aos';
import TransactionForm from './transactionForm';
AOS.init({
    offset: 200,
    duration: 800,
    easing: 'ease-in-out-sine',
    delay: 200,
    mirror: true
});




function Hero (){
    const [preview, setPreview] = useState('');
    const isImage = (url) => {
        const imageRegex = /\.(jpeg|jpg|gif|png|bmp|svg)$/i; 
        const httpRegex = /^https?:\/\//i; 
        return imageRegex.test(preview) && httpRegex.test(preview)!= null;
    }
    return (
        <div className="v-center">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6" style={{"display": "flex", "justifyContent": "flex-end",
    "flexDirection": "column"}}>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="color"
                                    data-aos="fade-up"
                                    data-aos-delay="0"
                                    data-aos-duration="1000"
                                    data-aos-easing="ease"
                                    data-aos-once="true"
                                >exploring posibilities
                                </h6>
                                <div className="spacer-20"></div>
                                <div className="h1 title-main"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="1000"
                                    data-aos-easing="ease"
                                    data-aos-once="true"
                                >
                                    <img src="./img/logo.png" className="img-fluid" alt="logo"/>
                                </div>
                            </div>
  
                        </div>
                        <TransactionForm onPreview={setPreview} />
                       
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 img-main"  style={{"overflowY": "auto", "height": "500px"}}>
                    {isImage(preview) ? 
                            <img src={preview} alt="Preview"/> : 
                            <p>{preview}</p>}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;