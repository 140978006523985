import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";


const Navbar= function() {

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const flytext = document.getElementById("fly");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
       btn_icon(false);
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          flytext.classList.add("hide");
        } else {
          header.classList.remove("sticky");
          flytext.classList.remove("hide");
          totop.classList.remove("show");
        } 
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return(
        <nav className="navbar transition">
        <div className="container d-flex justify-content-center">
          {/* Desktop menu Here */}
          <div className="dekstopmenu ">
             <ul className="navbar-nav text-center">
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="hero-area">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="latest-gallery">
                  Latest
                </Link>
              </li>

              {/*<li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="#">
                  My Inscriptions
                </Link>
              </li>

              <li className="nav-item">
                  <a className="nav-link" href="/about">About</a>
              </li>hue*/}
           </ul>
          </div>
          {/* Desktop menu Here */}

          {/* mobile menu here */}
          {showmenu && 
          <div className="mobilemenu" >
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
            <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="hero-area">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="latest-gallery">
                  Latest
                </Link>
              </li>

              {/*<li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="#">
                  My Inscriptions
                </Link>
              </li>

              <li className="nav-item">
                  <a className="nav-link" href="/about">About</a>
              </li>*/}
            </ul>
          </div>
          }
          <button className="burgermenu" type="button" onClick={() => btn_icon(!showmenu)}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          {/* mobile menu here */}
        </div>
      </nav>
    )
}

export default Navbar;