import React, { useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { parseEther } from '@ethersproject/units';
import { arrayify, hexlify } from '@ethersproject/bytes';
import { toUtf8Bytes } from '@ethersproject/strings';

function TransactionForm({ onPreview }) {

  //  1const { activate, active, library, account, chainId } = useWeb3React();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [provider, setProvider] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        if(message.length>0){
          clear();

          const web3provider = new Web3Provider(window.ethereum);
          setProvider(web3provider);
  
          const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
          await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: _chainId }] }); // Switches chain
  
          const signer = web3provider.getSigner();
  

          const transaction = {
            to: process.env.REACT_APP_CONTRACT_ADDRESS,
            value: parseEther('0'),
            data: arrayify(toUtf8Bytes(message)),
            gasLimit: hexlify(42000),  // assuming a standard transaction
            gasPrice: await web3provider.getGasPrice(),
          };
          const response = await signer.sendTransaction(transaction);
          console.log('Transaction response:', response);

          setInfo('Scription Minted! :)');
          setMessage('');
        }else{
          setError('You need to write a message first.');
        }
       

      } catch (error) {
       // debugger;
       //setError(error);
      }
    } else {
      setError('Please install an Ethereum wallet extension, such as MetaMask.');
    }
  };

  const clear = async () => {
    setInfo(null);
    setError(null);
   
    onPreview(null);
  };


  const previewScription = async () => {
    if(message.length>0){
      clear();
      onPreview(message);
    }else{
      setError('You need to write a message first.');
    }
  };

  
  return (
    <div>
      <br/>
      <p className='text-danger'>{error}</p>
      
      <p className='text-success'>{info} </p>
      <textarea
  className="input-main"
  placeholder="Message"
  value={message}
  maxLength={12000}
  onChange={(e) => {
    if (e.target.value.length <= 12000) {
        setMessage(e.target.value);
    }else{
      setError('Character limit exceeded');
    }
}}
/>
      <br/><br/>
      <button className="button-main" onClick={previewScription}>Preview</button>

      <button className="button-main" onClick={connectWallet}>Mint</button>
    </div>
  );
};

export default TransactionForm;