import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/Navbar';
import Hero from '../section/hero';
import Gallery from '../section/gallery';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

function home() {
  return (
    <>
    <GlobalStyles/>
    <Preloader/>
    <div className="home">

      <header id="header-wrap">
        <Navbar />
      </header>

      {/* HERO */}
      <section id="hero-area" className="bg-bottom py-0">
        
        <Parallax strength={300}>
        <Hero/>
        
        <Link smooth spy to="latest-gallery">
          <span className="mouse transition" id="fly">
              <span className="scroll"></span>
          </span>
        </Link>
        </Parallax>
      </section>

      
      {/* Gallery */}
      <section id="latest-gallery">
        
        <Gallery/>
       
      </section>

      <Footer/>

      <div className="float-text">
          <div className="de_social-icons">
              <a href="https://twitter.com/thescriptions/" target="_blank">
                <span className="buton"><i className="fa fa-twitter fa-lg"></i></span>
              </a>
              {/*<span className="buton"><i className="fa fa-instagram fa-lg"></i></span>*/}
          </div>
          <span>Follow Us</span>
      </div>
    </div>
    <ScrollToTopBtn />
    </>
  );
}

export default home;
