import React, { useState, useEffect } from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

function Gallery() {
  const isImage = (imgurl) => {
    const imageRegex = /\.(jpeg|jpg|gif|png|bmp|svg)$/i;
    const httpRegex = /^https?:\/\//i;
    return imageRegex.test(hexToUtf8(imgurl)) && httpRegex.test(hexToUtf8(imgurl));
  }

  const networkAPIs = {
    1: ['https://api.etherscan.io/api', process.env.REACT_APP_ETHERSCAN_API_KEY, 'Ethereum', 'https://etherscan.io/tx/'],
    56: ['https://api.bscscan.com/api', process.env.REACT_APP_BSCSCAN_API_KEY, 'Binance Smart Chain', 'https://bscscan.com/tx/'],
    137: ['https://api.polygonscan.com/api', process.env.REACT_APP_POLYGON_API_KEY, 'Polygon', 'https://polygonscan.com/tx/']
  };
  const [network, setNetwork] = useState(1);

  const quotes = [
    {quote: "The blockchain solves the problem of manipulation. When I talk to people, I sometimes say: 'Hey, do you know what seems like magic? Anything seen for the first time.'", author: "Vitalik Buterin"},
    {quote: "Blockchain makes cryptography economic. Cryptocurrencies make the economy cryptographic.", author: "Balaji Srinivasan"},
    {quote: "The root problem with conventional currency is all the trust that's required to make it work.", author: "Satoshi Nakamoto"},
    {quote: "On a blockchain, you can build a small universe within a machine.", author: "Vitalik Buterin"},
    {quote: "Bitcoin is the Internet of money. Currency is just the first application.", author: "Andreas Antonopoulos"},
    {quote: "Cryptocurrencies could be the key to enabling life on Mars.", author: "Elon Musk"},
    {quote: "Bitcoin offers a viable alternative to paper money. As the first digital currency without a state, managed by its users around the world.", author: "Marc Andreessen"},
    {quote: "We are living a crucial period in the history of digital currencies. This is the moment when cryptocurrencies are becoming 'mainstream'.", author: "Brian Armstrong"},
    {quote: "Bitcoin is a tool for freeing humanity from the oligarchy of central banks.", author: "Naval Ravikant"},
    {quote: "Bitcoin to me is not a currency, it's a movement.", author: "Jack Dorsey"}
];

  
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const handleBtnClick = (tx) => {
    setSelectedTransaction(tx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose = () => {
    setSelectedTransaction(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  const [transactionsImages, setTransactionsImage] = useState([]);
  const [transactionsRaw, setTransactionsRaw] = useState([]);
  const [currentQuote, setCurrentQuote] = useState(null);
  const hexToUtf8 = (hex) => {
    if (hex.startsWith('0x')) {
      hex = hex.slice(2);
    }

    const bytes = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    const decoder = new TextDecoder('utf-8');
    const utf8String = decoder.decode(bytes);

    return utf8String;
  };

  useEffect(() => {
    setCurrentQuote(quotes[Math.floor(Math.random()*quotes.length)]);
    const fetchTransactions = async () => {
      try {
        const baseURL = networkAPIs[network][0]; 
        const response = await fetch(`${baseURL}?module=account&action=txlist&address=${process.env.REACT_APP_CONTRACT_ADDRESS}&apikey=${networkAPIs[network][1]}`);

        const data = await response.json();
        if (data.status === '1') {
          const transactions = data.result;
          let images = [];
          let raws = [];
          transactions.forEach((tx) => {
              if(isImage(tx.input)){
                  images.push(tx);
              } else if(!tx.contractAddress) {
                  raws.push(tx);
              }
          });

          setTransactionsImage(images); // substitui a lista antiga de imagens
          setTransactionsRaw(raws); // substitui a lista antiga de raws

       
        } else {
          console.error('Failed to fetch transactions:', data.message);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions(); // chama a função uma vez na montagem
    const interval = setInterval(fetchTransactions, 10000); // chama a função a cada 10 segundos

    return () => {
      clearInterval(interval); // limpa o intervalo quando o componente é desmontado
    };
}, [network]);



  const timestampToDate = (timestamp) => {
    let date = new Date(timestamp * 1000); 
    let day = date.getDate();
    let month = date.getMonth() + 1; 
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();

    let formattedTime = month + '/' + day + '/' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    
    return formattedTime;
}

  return (
    <div className="container">
      <GlobalStyles />
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Latest Scriptions</h2>
          <div className="space-border"></div>
        </div>
        <div className="text-center mb-4">
          <button className={`button-main ${network === 1 ? 'active' : ''}`} onClick={() => setNetwork(1)}>Ethereum</button>
          <button className={`button-main ${network === 56 ? 'active' : ''}`} onClick={() => setNetwork(56)}>Binance Smart Chain</button>
          <button className={`button-main ${network === 137 ? 'active' : ''}`} onClick={() => setNetwork(137)}>Polygon</button>
        </div>
      </div>
      <div>
      </div>


      <Masonry
        className={"row my-gallery-class"} elementType={"div"}>
        {transactionsImages.map((tx) => (
          
          <div className="col-lg-4 image-element-class de_modal de_modal" 
            onClick={() => handleBtnClick(tx)}
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="card-image-1">
              <div className="d-text">
                <h3>{tx.from.substring(0, 10)} [..]</h3>
                <h5 className="d-tag">{tx.hash}</h5>
              </div>
              <img src={hexToUtf8(tx.input)} alt="gallery" />
            </div>
          </div>
        ))}
         {transactionsRaw.map((tx) => (
          
          <div className="col-lg-4 text-element-class de_modal de_modal " 
            onClick={() => handleBtnClick(tx)}
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="card-image-1">
              <div className="d-text">
                <h3>{tx.from.substring(0, 10)} [..]</h3>
                <h5 className="d-tag">{tx.hash}</h5>
              </div>
              {hexToUtf8(tx.input)} 
            </div>
          </div>
        ))}
      </Masonry>

      {/* lightbox1 */}
      {selectedTransaction && ( 
      <div className="LightboxGal">
        <div className="closeGal">
            <button className="button-close" onClick={handleBtnClickclose}></button>
        </div>
        <div className="v-center w-100">
          <div className="mainLightbox container">
            <div className="row g-5">
                <div className="col-lg-6">
                  { isImage(selectedTransaction.input) ? 
                  (<img src={hexToUtf8(selectedTransaction.input)} alt="" className="img-fluid"/>) :
                    (<p>{hexToUtf8(selectedTransaction.input)}</p>)
                  }
                </div>

                <div className="col-lg-6 de_project-info">
                    <h3 style={{"overflowWrap": "break-word"}}>Scription {selectedTransaction.hash}
                    &nbsp; <a href={networkAPIs[network][3]+selectedTransaction.hash} target="_blank" rel="noreferrer"><i className="fa fa-external-link"></i></a> </h3>
                    <p>owned by {selectedTransaction.from}</p>
                    
                    <div className="de_project-details">
                        <div className="d-field">
                            <i className="fa fa-user-o"></i>Contract: <span>{selectedTransaction.to}</span>
                        </div>
                        <div className="d-field">
                            <i className="fa fa-file-text-o"></i>Chain: <span>{networkAPIs[network][2]}</span>
                        </div>
                        <div className="d-field">
                            <i className="fa fa-calendar-o"></i>Created at: <span>{timestampToDate(selectedTransaction.timeStamp)}</span>
                        </div>
                    </div>
                    <div className="spacer-30"></div>
                    <blockquote>
                    "{currentQuote.quote}"
                        <span> {currentQuote.author}</span>
                    </blockquote>
                </div>
            </div>
          </div>
        </div>
      </div>
      )}

    </div>
  );
}

export default Gallery;
