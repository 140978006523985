import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/pages/home";
import About from "../src/pages/about";

function App() {
  return (
  	<div>
	  <BrowserRouter>
	    <Routes>
		    <Route path = "/" element={< Home/>}/>
			{/*<Route path = "/about" element={< About/>}/>*/}
		</Routes>
	  </BrowserRouter>
    </div>
  );
}

export default App;