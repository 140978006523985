import NavbarAbout from '../layout/NavbarAbout';
import Blockquote from '../section/blockquoteLawyer';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';
import Team from '../section/team';


const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

function about() {
  return (
    <>
    <GlobalStyles/>
    <Preloader/>
    <div className="home">

      <header id="header-wrap">
        <NavbarAbout />
      </header>

       {/* What I DO */}
       <section id="whatido">
        <Blockquote/>
        <span className="mouse transition" id="fly">
              <span className="scroll"></span>
          </span>
      </section>

      {/* Team */}
      <section id="team" className="pb-0">
        <Team/>
      </section>

      <Footer/>

      <div className="float-text">
          <div className="de_social-icons">
              <a href="https://twitter.com/thescriptions/" target="_blank">
                <span className="buton"><i className="fa fa-twitter fa-lg"></i></span>
              </a>
              {/*<span className="buton"><i className="fa fa-instagram fa-lg"></i></span>*/}
          </div>
          <span>Follow Us</span>
      </div>
    </div>
    <ScrollToTopBtn />
    </>
  );
}

export default about;
